import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'LandingPage', component: () => import('../Pages/Home.vue') },
    { path: '/SServices', name: 'AboutUs', component: () => import('../Pages/About.vue') },
    { path: '/Contact', name: 'ContactUs', component: () => import('../Pages/Contact.vue') },
    { path: '/Services', name: 'Services', component: () => import('../Pages/Services.vue') },
    { path: '/ProjectsTech', name: 'ProjectsTech', component: () => import('../Pages/ProjectsTech.vue') },
    { path: '/Customer', name: 'CustomerPage', component: () => import('../Pages/Customer.vue') },
    { path: '/Page1', name: 'EcomProject1', component: () => import('../Pages/Page1.vue') },
    { path: '/Page2', name: 'EcomProject2', component: () => import('../Pages/Page2.vue') },
    { path: '/Page3', name: 'EcomProject3', component: () => import('../Pages/Page3.vue') },
    { path: '/Page4', name: 'EcomProject4', component: () => import('../Pages/Page4.vue') },
  ],
});

export default router;

<template>
  <div id="app">
    <NavbarComponent />
    <button @click="scrollToTop" v-if="showScrollButton" class="scroll-top-button">↑</button>
    <router-view></router-view>
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/styles/global.css';
import NavbarComponent from './components/NavbarComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
  },
  data() {
    return {
      showScrollButton: false
    };
  },
  mounted() {
    AOS.init({
      offset: 200,
      duration: 600,
      mirror: true
    });
    window.addEventListener('scroll', this.handleScroll);
    this.$router.afterEach(() => {
      this.scrollToTop();
    });
  },
  methods: {
    scrollToTop() {
      // Smooth scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      // Check if user has scrolled down beyond certain threshold
      if (window.pageYOffset > 200) { // You can adjust the threshold as needed
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style>
@font-face {
  font-family: "BankGothic";
  font-family: "GowunBatang";
  font-family: "Playfair Display";
  font-family: "Corben";
  src: local("Playfair Display"),
    url(fonts/static/PlayfairDisplay-Medium.ttf) format("truetype");
  src: local("Corben"),
    url(fonts/Corben-Regular.ttf) format("truetype");
  src: local("GowunBatang"),
    url(fonts/GowunBatang-Regular.ttf) format("truetype");
    src: local("BankGothic"),
    url(fonts/BankGothic.ttf) format("truetype");
}
.scroll-top-button {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
    color: #1b1717;
    padding: 10px 20px;
    border: none;
    border-radius: 54px !important;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    font-size: 20px;
}
.scroll-top-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5));
  border-radius: 22px;
  z-index: -1;
  transition: transform 0.3s ease-in-out; 
  transform: translateY(100%); 
}

.scroll-top-button:hover::before {
  transform: translateY(0); 
}

.scroll-top-button:hover {
  background-color: #0099cc; 
}
</style>

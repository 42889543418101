<template>
  <nav class="navbar">
    <!-- Sidebar for Small Screens -->
    <div v-if="isResponsive" class="sidebar" :class="{ 'show-sidebar': showSidebar }">
  <div class="logo-1"><img src="@/assets/images/blue logo.png" alt=""></div>
  <div class="search-bar">
    <input type="text" placeholder="Search">
  </div>
  <button @click="toggleSidebar" class="close-icon">×</button>
  <div class="router-link">
    <div class="router-link">
      <router-link to="/" @click="toggleSidebar" class="Software-1" v-bind:class="{ 'active': isActive('/') }">Home</router-link>
    </div>
  </div>
  <div class="router-link">
    <div class="router-link">
      <router-link to="/SServices" @click="toggleSidebar" class="Software-1" v-bind:class="{ 'active': isActive('/SServices') }">About</router-link>
    </div>
  </div>
  <div class="router-link">
    <div class="router-link">
      <router-link to="/ProjectsTech" @click="toggleSidebar" class="Software-1" v-bind:class="{ 'active': isActive('/ProjectsTech') }">Projects</router-link>
    </div>
  </div>
  <div class="router-link">
    <div class="router-link">
      <router-link to="/Contact" @click="toggleSidebar" class="Software-1" v-bind:class="{ 'active': isActive('/Contact') }">Contact Us</router-link>
    </div>
  </div>
  <div class="router-link">
    <div class="router-link">
      <router-link to="/Services" 
        @click="toggleSidebar"  :class="{ 'active': isActive('/Services'), 'services-active': isDropdownActive }" class="Software-1">Services
      </router-link>
      <img class="drop-img" src="@/assets/images/drop.png" alt="">
      <transition name="fade">
        <div class="dropdown-content" @click="toggleSidebar" >
          <router-link @click="underlineServicesLink('/Services/page4')"  to="/page4">Search Marketing Services</router-link>
          <router-link @click="underlineServicesLink('/Services/page2')" to="/page2">Website Design & Development</router-link>
          <router-link @click="underlineServicesLink('/Services/page1')" to="/page1">E-commerce Design & Development</router-link>
          <router-link @click="underlineServicesLink('/Services/page3')" to="/page3">Website Maintenance & Management</router-link>
        </div>
      </transition>
    </div>
  </div>
  <div class="router-link">
    <div class="router-link">
      <div class="Software-1">
        Help?
      </div>
    </div>
  </div>
  <div class="navbar-left-1">
  </div>
</div>

    <!-- Second Line of Navbar -->
    <div :class="{ 'fixed': isFixed }" class="nav-main">
      <div class="navbar-bottom">
        <!-- Left Side -->
        <div class="navbar-bottom-left">
          <router-link to="/" class="logo">
            <img src="@/assets/images/blue logo.png" alt="">
          </router-link>
          <div class="router-link-1">
            <router-link to="/" class="Software-1" v-bind:class="{ 'active': isActive('/') }">Home</router-link>
          </div>
          <div class="router-link-1">
            <router-link to="/SServices" class="Software-1"
              v-bind:class="{ 'active': isActive('/SServices') }">About</router-link>
          </div>
          <div class="router-link-1">
            <router-link to="/ProjectsTech" class="Software-1"
              v-bind:class="{ 'active': isActive('/ProjectsTech') }">Projects</router-link>
          </div>
          <div class="router-link-1">
            <router-link to="/Contact" class="Software-1" v-bind:class="{ 'active': isActive('/Contact') }">Contact
              Us</router-link>
          </div>
          <div class="router-link-1">
            <div class="router-link" @click="toggleServicesActive">
              <router-link to="/Services" class="Software-2"
                :class="{ 'active': isActive('/Services'), 'services-active': isDropdownActive }">Services</router-link>
              <img class="drop-img" src="@/assets/images/drop.png" alt="">
              <div class="dropdown-content">
                <router-link @click="underlineServicesLink('/Services/page4')" to="/page4">Search Marketing
                  Services</router-link>
                <router-link @click="underlineServicesLink('/Services/page2')" to="/page2">Website Design &
                  Development</router-link>
                <router-link @click="underlineServicesLink('/Services/page1')" to="/page1">E-commerce Design &
                  Development</router-link>
                <router-link @click="underlineServicesLink('/Services/page3')" to="/page3">Website Maintenance &
                  Management</router-link>
              </div>
            </div>
          </div>

          <div class="router-link-1">
            <router-link to="/Customer" class="Software-1"
              v-bind:class="{ 'active': isActive('/Customer') }">Help?</router-link>
          </div>
        </div>
      </div>
      <div class="nav-icon">
        <button v-if="isResponsive" @click="toggleSidebar" class="menu-icon">&#9776;</button>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'NavbarComponent',
  data() {
    return {
      selectedLanguage: '',
      isFixed: false,
      isResponsive: false,
      showSidebar: false,
    };
  },
  computed: {
    isDropdownActive() {
      return ['/page1', '/page2', '/page3', '/page4'].includes(this.$route.path);
    },
  },
  mounted() {
    this.checkResponsive();

    window.addEventListener('resize', this.checkResponsive);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkResponsive);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    underlineServicesLink(parentPath) {
      if (this.$route.path.startsWith(parentPath)) {
        return true;
      }
      return false;
    },
    isActive(path) {
      if (path === '/Services' && this.isDropdownActive) {
        return true; 
      }
      const currentPath = this.$route.path;
      return currentPath === path || currentPath.startsWith(path + '/');
    },

    checkResponsive() {
      this.isResponsive = window.innerWidth <= 1200;
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    handleScroll() {
      this.isFixed = window.pageYOffset > 50;
    },


  },
};
</script>
<style scoped>
.active {
  border-radius: 0 0 0 0;
  border-bottom: 3.5px solid #00DDFF;
  /* Customize border style here */
}
.active, .services-active {
  border-radius: 0 0 0 0;
  border-bottom: 3.5px solid #00DDFF
  /* Customize border style here */
}

.router-link-1.active {
  position: relative;
}

.router-link-1.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgb(0, 221, 255);
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
}

.router-link-1.active::after {
  transform: scaleX(1);
}

.router-link-1.active:hover::after,
.router-link-1.active:focus::after,
.router-link-1.active:active::after {
  transform-origin: bottom left;
}

.drop-img {
  margin-top: 30px;
  width: 42px;
  height: 30px;
  margin-left: -44px;
}

.router-link {
  position: relative;
  display: flex;
}

.dropdown-content a {
  color: rgb(0, 0, 0);
  transition: color 0.3s ease;
}

.dropdown-content a:hover {
  color: #3498db;
}

.dropdown-content {
  line-height: 2rem;
  color: #000 !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 10px;
  width: 300px !important;
  pointer-events: none;
  position: absolute;
  background: linear-gradient(135deg, #cdffd8, #94b9ff);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 54px;
  left: -52px;
  border-radius: 10px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s, transform 0.5s;
}

@media(max-width:950px) {
  .dropdown-content {
    width: 281px !important;
    top: 54px;
    left: 14px !important;
  }
}

@media(max-width:800px) {
  .dropdown-content {
    line-height: 2rem;
    color: #000 !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 10px;
    width: 300px !important;
    position: absolute;
    background: linear-gradient(135deg, #cdffd8, #94b9ff);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 73px;
    left: 32px;
    border-radius: 10px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s, transform 0.5s;
  }
}

@media (max-width:390px) {
  .dropdown-content {
    width: 280px !important;
  }
}

.router-link:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-content router-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content router-link:hover {
  background-color: #f1f1f1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.router-link:hover .dropdown-content {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>